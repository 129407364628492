<template>
  <div class="main-wrap">
    <!--    <h3 class="title">Welcome!</h3>-->
    <p class="sub-title">请选择您要进入的平台</p>
    <div class="row">
      <div :class="current==1?'item_hover':'item'" @mouseleave="clearIndex()"
           @mouseover="changeIndex(1)">
        <div style="padding: 20px">
          <div class="icon">
            <img class="img" src="@/assets/images/operate_new.png"/>
          </div>
          <div class="platformTitle">
            <div class="label">运营平台</div>
            <div class="labelEnglish">Operation platform</div>
            <div class="enterButton" @click="toOperate">进入</div>
          </div>
        </div>

      </div>
      <div :class="current==2?'item_hover':'item'" @mouseleave="clearIndex()"
           @mouseover="changeIndex(2)">
        <div style="padding: 20px">
          <div class="icon">
            <img class="img" src="@/assets/images/share_new.png"/>
          </div>
          <div class="platformTitle">
            <div class="label">共享平台</div>
            <div class="labelEnglish">Sharing platform</div>
            <div class="enterButton" @click="toShare">进入</div>
          </div>
        </div>
      </div>
      <div :class="current==3?'item_hover':'item'" @mouseleave="clearIndex()"
           @mouseover="changeIndex(3)">
        <div style="padding: 20px">
          <div class="icon">
            <img class="img" src="@/assets/images/net_new.png"/>
          </div>
          <div class="platformTitle">
            <div class="label">联网平台</div>
            <div class="labelEnglish">Networking platform</div>
            <div class="enterButton" @click="toNet">进入</div>
          </div>

        </div>
      </div>
      <div :class="current==4?'item_hover':'item'" @mouseleave="clearIndex()"
           @mouseover="changeIndex(4)">
        <div style="padding: 20px">
          <div class="icon">
            <img
                class="img"
                src="@/assets/images/data_board_new.png"
            />
          </div>
          <div class="platformTitle">
            <div class="label">数据大屏</div>
            <div class="labelEnglish">Large data screen</div>
            <div class="enterButton" @click="toDataBoard">进入</div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "main",
  data() {
    return {
      platformList: [],
      current: 0,
    };
  },
  created() {
    const permissions = JSON.parse(window.localStorage.getItem("permissions"));
    console.log(permissions, '权限！！！！！！！')
    this.platformList = permissions.filter(
        (item) => item.permissionCode.length === 3
    );
  },
  methods: {
    hasPermission(code) {
      return (
          this.platformList.filter((item) => item.permissionCode === code)
              .length > 0
      );
    },
    savePlatform(platformCode) {
      window.localStorage.setItem("currentPlatform", platformCode);
    },
    changeIndex(index) {
      this.current = index
    },
    clearIndex() {
      this.current = 0
    },
    toOperate() {
      this.$store.commit("app/clearRouteMenu");
      this.$router.push("/home");
      this.savePlatform("001");
    },
    toShare() {
      this.$store.commit("app/clearRouteMenu");
      this.$router.push("/share");
      this.savePlatform("002");
    },
    toNet() {
      this.$store.commit("app/clearRouteMenu");
      this.$router.push("/net");
      this.savePlatform("003");
    },
    toDataBoard() {
      this.$store.commit("app/clearRouteMenu");
      this.$router.push("/data_board");
      // this.savePlatform("004");
    },
  },
};
</script>

<style lang="less" scoped>
.main-wrap {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  background-image: url("../assets/images/login_bg_new.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  flex-direction: column;

  .title {
    font-size: 120px;
    color: #fff;
    font-weight: bold;
    margin-top: 100px;
    text-align: center;
    white-space: nowrap;
  }

  .sub-title {
    font-weight: 400;
    font-size: 60px;
    color: #fff;
    text-align: center;
    margin-top: 150px;
    white-space: nowrap;
    margin-bottom: 150px;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 1300px;

    .item {
      //background: rgba(255, 255, 255, 0.5);
      //backdrop-filter: blur(60px);
      //
      //border-radius: 12px;
      //width: 273px;
      //height: 328px;
      //margin: 40px;
      background: linear-gradient(180deg, #FFFFFF 0%, #ECF4FF 100%);
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.4);
      border-radius: 0 0 0 0;
      //padding: 20px;
      opacity: 1;
      width: 284px;
      height: 400px;
      display: flex;
      flex-direction: column;
      //justify-content: center;
      align-items: center;
      //cursor: pointer;

      //:hover{transform:scale(1.2)}

      .icon {
        width: 250px;
        height: 188px;
        margin-bottom: 20px;
        border: 1px dashed;

        img {
          max-width: 100%;
          height: 188px;
        }
      }

      .enterButton {
        width: 138px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #0768FD;
        cursor: pointer;
        margin-top: 50px;
      }

      .platformTitle {
        display: flex;
        flex-direction: column;
        align-items: center
      }

      .label {
        font-size: 28px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #414344;
        line-height: 33px;
      }

      .labelEnglish {
        font-size: 19px;
        font-family: Gotham-Extra Light, Gotham;
        font-weight: normal;
        color: #414344;
        line-height: 22px;
        opacity: 0.4;
      }
    }

    .item_hover {
      //background: rgba(255, 255, 255, 0.5);
      //backdrop-filter: blur(60px);
      //
      //border-radius: 12px;
      //width: 273px;
      //height: 328px;
      //margin: 40px;
      background: linear-gradient(180deg, #FFFFFF 0%, #ECF4FF 100%);
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.4);
      border-radius: 0 0 0 0;
      //padding: 20px;
      opacity: 1;
      width: 324px;
      height: 456px;
      display: flex;
      flex-direction: column;
      //justify-content: center;
      align-items: center;
      //cursor: pointer;

      //:hover{transform:scale(1.2)}

      .icon {
        width: 280px;
        height: 210px;
        margin-bottom: 20px;
        border: 1px dashed;

        img {
          max-width: 100%;
          height: 210px;
        }
      }

      .enterButton {
        width: 138px;
        height: 40px;
        background: #0768FD;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #FFFFFF;
        cursor: pointer;
        margin-top: 50px;
      }

      .platformTitle {
        display: flex;
        flex-direction: column;
        align-items: center
      }

      .label {
        font-size: 28px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #414344;
        line-height: 33px;
      }

      .labelEnglish {
        font-size: 19px;
        font-family: Gotham-Extra Light, Gotham;
        font-weight: normal;
        color: #414344;
        line-height: 22px;
        opacity: 0.4;
      }
    }
  }
}
</style>
